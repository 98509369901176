$popularEvents-header-background-img: 'themes/base/img/section-header-pattern/section-header-pattern.png' !default;
$popularEvents-header-title-background: $white !default;
$popularEvents-header-title-color: $primary-blue !default;

$popularEvents-club-color: $white !default;
$popularEvents-club-hover-background-1: $primary-blue !default;
$popularEvents-club-hover-background-2: $secondary-green !default;
$popularEvents-club-hover-booking-background: $secondary-green !default;
$popularEvents-club-hover-booking-button-background: $white !default;
$popularEvents-club-hover-booking-button-color: $primary-blue !default;

.header {
	height: 62px;
	margin: 40px 0;
	line-height: 62px;
	text-align: center;
	background-image: url('#{$default_dir}#{$popularEvents-header-background-img}');
	background-repeat: repeat-x;
	background-position: center;
	background-size: contain;

	.title {
		display: inline-block;
		width: auto;
		padding: 0 20px;
		font-size: 28px;
		color: $popularEvents-header-title-color;
		background-color: $popularEvents-header-title-background;

		@include sf_pro_textsemibold;

		@include media-breakpoint-down(md) {
			font-size: 23px;
		}
	}
}

.clubsContainer {
	width: 100%;

	@include media-breakpoint-down(md) {
		position: relative;
		display: block;
		overflow-x: auto;
		overflow-y: hidden;
		white-space: nowrap;
	}

	.club {
		position: relative;
		display: block;
		padding: 0;
		margin: 0 4px 8px;
		cursor: pointer;

		@include media-breakpoint-up(md) {
			&.col-md-6 {
				flex: 0 0 48%;
				max-width: 48%;
			}
		}

		@include media-breakpoint-up(lg) {
			&.col-lg-4 {
				flex: 0 0 32.33333%;
				max-width: 32.33333%;
			}
		}

		@include media-breakpoint-down(md) {
			display: inline-block;
			width: 90%;
			height: auto;
			padding: 0;
			margin: 0 10px 0 0;
		}

		img {
			width: 100%;
			max-width: 100%;
			height: auto;
		}

		.details {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			padding: 15px;
			margin: 0;
			overflow: hidden;
			color: $popularEvents-club-color;

			&:before {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				content: '';
				background-image: linear-gradient(to bottom, rgba($popularEvents-club-hover-background-1, 0.75) 0%, rgba($popularEvents-club-hover-background-2, 0.75) 75%);
				opacity: 0;
				transition: all 0.25s ease 0s;
			}

			.transparentLink {
				position: absolute;
				top: 0;
				left: 0;
				z-index: 3;
				width: 100%;
				height: 100%;
			}

			.description {
				position: absolute;
				bottom: 25px;
				transition: all 0.25s ease 0s;

				h3 {
					@include sf_pro_textsemibold;
					margin-bottom: 6px;
					font-size: 27px;
					color: $popularEvents-club-color;
				}

				.location {
					@include sf_pro_textlight;
					font-size: 15px;
					line-height: 16px;
					color: $popularEvents-club-color;
					vertical-align: middle;

					i {
						color: $popularEvents-club-color;
					}
				}
			}

			&:hover {
				text-decoration: none;
			}

			.booking {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 0px;
				background-color: $popularEvents-club-hover-booking-background;
				opacity: 0;
				transition: height 0.25s ease 0s;

				span {
					@include sf_pro_textlight;
					padding: 0 0 0 15px;

					&[class*="eventTripsIcon-"] {
						padding: 0 0 0 5px;
						font-size: 20px;
					}
				}

				ul.inPrice {
					padding: 0 0 0 15px;
					margin: 0;

					li {
						display: inline-block;
						color: $popularEvents-club-color;
						list-style: none;
					}
				}

				a.button {
					position: absolute;
					top: 15px;
					right: 15px;
					width: auto;
					height: 35px;
					padding: 10px;
					margin: 0;
					font-size: 15px;
					font-weight: normal;
					color: $popularEvents-club-hover-booking-button-color;
					white-space: nowrap;
					background-color: $popularEvents-club-hover-booking-button-background;
					border: none;
					opacity: 0;
					transition: opacity 0.25s ease 0s;

					@include sf_pro_textsemibold;

					@include media-breakpoint-down(sm) {
						font-size: 12px;
					}
				}
			}
		}

		&:hover {
			.details {
				&:before {
					opacity: 1;
					transition: all 0.25s ease 0s;
				}

				.description {
					bottom: 76px;
					transition: all 0.25s ease 0s;
				}

				.booking {
					height: 65px;
					opacity: 1;
					transition: height 0.25s ease 0s;

					a.button {
						opacity: 1;
						transition: opacity 0.25s ease 0s;
					}
				}
			}
		}
	}
}

a {
	text-decoration: none;
}