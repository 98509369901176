@use 'sass:map';

@import "../../football/styles/homepage";

body {
	.homepage .container {
		&.media_partners {
			@import "layout/homepage/media-partners";
		}

		&.popular_events {
			.row {
				margin: 0;

				@import "layout/homepage/popular-events";
			}
		}
	}

	.change-order {
		display: flex;
		flex-direction: column;

		.calendar.container {
			order: 2;
		}

		.after-calendar-container {
			order: 1;
		}
	}

	.modal.video .modal-dialog {
		max-width: #{map.get($container-max-widths, sm)};

		.modal-body {
			text-align: center;
		}
	}
}